@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

@font-face {
    font-family: "Nevis-Bold";
    src: url("../fonts/Nevis-Bold.ttf.woff") format("woff"),
        url("../fonts/Nevis-Bold.ttf.svg#Nevis-Bold") format("svg"),
        url("../fonts/Nevis-Bold.ttf.eot"),
        url("../fonts/Nevis-Bold.ttf.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

$primary: #3297bf;
$dark: #111;
$family-sans-serif: Roboto, sans-serif;
$title-family: "Nevis-Bold", Roboto, sans-serif;
$footer-padding: 2rem 1rem;
$block-spacing: 1em;
//$footer-background-color: $dark;
//$footer-color: #fff;

@import "~bulma/bulma";

$use-fade-in: true;
@import "~animate-sass";

*,
*:before,
*:after {
    position: relative;
}

@for $i from 1 through 10 {
    .delay-#{$i * 250} {
        animation-delay: #{$i * 250}ms;
    }
}

.hero {
    background: $primary;
    flex-grow: 1;
    min-height: auto !important;

    .hero-background {
        background: url(../img/bg.jpg) 50% 25% no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transform: scale(1.03);
        transition-property: transform, opacity;
        transition-duration: 500ms;
        transition-timing-function: ease-out;

        &.is-visible {
            transform: none;
            opacity: 1;
        }
    }

    .title {
        margin-bottom: $size-2;
    }

    hr {
        width: 280px;
        height: 1px;
    }
}

.has-text-uppercase {
    text-transform: uppercase;
}

.has-text-spaced {
    letter-spacing: 0.25em;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.is-dimmed {
    opacity: 0.5;
}
